export default [
  {
    value: 'Waiting Review',
    index: 'quote_processing',
    description: 'The user has accepted and attached the payment order, now our team have to review it and contact the user.',
  },
  {
    value: 'Quote Produced',
    index: 'quote_produced',
    description: 'The user has produced the quote, but did not accept it yet.',
  },
  {
    value: 'Quote Accepted By Customer',
    index: 'quote_accepted',
    description: 'The user has accepted the quote, but we are waiting for them to attach the payment order and submit it.',
  },
  {
    value: 'Quote Cancelled By Customer',
    index: 'quote_cancelled',
    description: 'The quote has been cancelled by the customer',
  },
  {
    value: 'NIHSS+ Accepted',
    index: 'nihss_accepted',
    description: 'Our team have reviewed the NIHSS+ Quote and accepted it',
  },
  {
    value: 'Waiting User Registration',
    index: 'quote_pre_accepted',
    description: 'The user has produced the quote and we are waiting for the user to register.',
  },
];

