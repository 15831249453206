<template>
  <div>
    <va-card
      class="sampleOne"
      ref="printableCard"
      hidden
    >
      <template slot="header">
        <div>
          <span style="font-size: 30px; color: #434343;">NIHSS+ Organization Quote</span>
          <p style="color: #4b4b4b;">
            N°: {{ this.$route.params?.id }}
          </p>
        </div>
      </template>
      <template>
        <div style="color: #303030;">
          <b>Organization: </b> {{ quote.name }} <br>
          <b>Main Contact: </b> {{ quote.main_name }} <br>
          <b>Main Phone/Mail: </b> {{ quote.main_contact }} <br>
          <hr class="my-2">
          <b>Country: </b> {{ quote.country }} <br>
          <b>State: </b> {{ quote.state }} <br>
          <b>City: </b> {{ quote.city }} <br>
          <hr class="my-2">
          <b>Subscription Amount: </b> {{ quote.subscriptions_requested }} <br>
          <b>Price per Subscription: </b> {{ pricePerQuote(quote.subscriptions_requested, true) }} <br>
          <b>Subtotal: </b> {{ calcTotalPrice(quote.subscriptions_requested) }} <br>
          <hr class="my-2">
          <p style="color: #cdcdcd;">
            Disclaimer: This document does <b>not represent a charge</b>, the purpose of this document is only to display an order summary, The information in this quote was provided by a user through the website <b>https://nihss.plus</b>, we are not responsible for incorrect information provided by the user, such as addresses, name of the organization and contact number, we assume that the information provided is valid and will be used to register this quote, if you encounter any problems, please contact us via <b>https://nihss.plus/faq</b>
          </p>
        </div>
     </template> 
   
    </va-card>

    <va-modal              
      v-model="showEmailPopup"
      hideDefaultActions
      class="flex xs12"
      title="Send Email"
      size="large"
      ok-text="Ok">

        <section>
          <div class="align--center">
            <p class="pb-2">Please enter the email address you wish to send a copy to.</p>
            <va-input
                v-model="userEmail"
                :placeholder="'User Email'"
                type="email"
                removable
              >
                <va-icon
                  name="fa fa-envelope"
                  slot="prepend"
                />
              </va-input>
          </div>
        </section>
        <div>
          <va-button
            slot="actions"
            @click="submitMessage"
            color="success"
            class="mr-2"
        > Submit </va-button>
        <va-button
            slot="actions"
            @click="showEmailPopup = !showEmailPopup"
            color="gray"
            class="mr-2"
        > Close </va-button>
        </div>
    </va-modal>
    <div class="flex md12">
    
      <va-card title="Quote Details">
        <div class="d-flex flex-center">
          <div class="flex md12 sm6 xs12">
            <p><strong>Main Contact</strong></p>
            <va-input
              v-model="quote.main_contact"
              placeholder="Main Contact"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-envelope-o"
              />
            </va-input>
            <p><strong>Main Name</strong></p>
            <va-input
              v-model="quote.main_name"
              placeholder="Main Name"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-envelope-o"
              />
            </va-input>
            <p><strong>Secondary Name</strong></p>
            <va-input
              v-model="quote.name"
              placeholder="Secondary Name"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-envelope-o"
              />
            </va-input>
            <p><strong>Secondary Contact</strong></p>
            <va-input
              v-model="quote.phone"
              placeholder="Secondary Contact"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-envelope-o"
              />
            </va-input>
            <p><strong>Organization State</strong></p>
            <va-input
              v-model="quote.state"
              placeholder="Quote State"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-envelope-o"
              />
            </va-input>
            <p><strong>Quote Status</strong></p>
            <va-select
              v-model="quote.status"
              key-by="index"
              text-by="value"
              :options="statuses"
            />
            <p><strong>Subscriptions Requested</strong></p>
            <va-input
              v-model="quote.subscriptions_requested"
              placeholder="Subscriptions Requested"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-envelope-o"
              />
            </va-input>
            <div>
              <va-button
                color="info"
                @click="goToOrganizationPage"
                :disabled="!quote.organization"
                :title="quote.organization ? 'Go to Organization Page' : 'There is none Organization Linked to this quote'"
              >
                <i
                  class="fa fa-lock"
                  style="margin-right: 0.8em; margin-top: 0.1em;"
                  v-if="!quote.organization"
                />
                View Organization
              </va-button>
              <va-button
                color="info"
                @click="printPDF"
                title="Send as Email"
              >
               Show as PDF
              </va-button>
              <va-button
                color="info"
                @click="sendEmailModal"
                title="Send as Email"
              >
                Send Email
              </va-button>
              <va-button
                color="info"
                @click="updateQuote"
              >
                Update
              </va-button>
            </div>
          </div>
        </div>
      </va-card>
      <va-card
        title="Quote Files"
        class="mt-5 mb-5"
      >
        <va-data-table
          :fields="fields"
          :data="filteredFiles"
          @row-clicked="viewAttachment"
          :per-page="perPage"
        >
          <template
            slot="actions"
            slot-scope="props"
          >
            <va-button
              small
              @click="viewAttachment(props.rowData)"
            >
              View Attachment
            </va-button>
          </template>
        </va-data-table>
      </va-card>
    </div>
  </div>
</template>

<script>
// import { SemipolarSpinner } from 'epic-spinners'
import firebase from 'firebase';
import {debounce} from 'lodash';
import axios from '@/scripts/interceptor.js';
import SeatPriceCalculator from '@/scripts/SeatPriceCalculator.js';
import statuses from '../../data/quoteStatus';
export default {
  name: 'Organizations',
  data: function() {
    return {
      statuses,
      email: '',
      loading: true,
      emailErrors: [],
      quote: {
        status: '',
      },
      showEmailPopup: false,
      userEmail: "",
      term: null,
      perPage: 10,
      statusOpt: [],
      perPageOptions: ['10', '50', '100', '250'],
    };
  },
  async created() {
    this.loading = true;
    try {
      const response = await axios.get(
          '/api/quotes/details/' + this.$route.params.id,
      );
      let status = this.statuses.find(
          (status) => status.index === response?.data?.message.status,
      );

      this.quote = response?.data?.message || [];
      this.quote.status = status || '';

    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    fields() {
      return [
        {
          name: 'name',
          label: 'Name',
          align: 'center',
          sortable: true,
        },
        {
          name: '__slot:actions',
          label: 'Action',
          align: 'center',
          sortable: true,
        },
      ];
    },
    filteredFiles() {
      return this.quote.attachments || [];
    },
  },
  methods: {
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100),
    showOrg(row) {
      this.$router.push({ name: "quoteDetails", params: { uid: row.id } });
    },
    getStyles() {
      const styles = document.head.querySelectorAll('style, link[rel="stylesheet"]');
      return Array.from(styles).reduce((acc, style) => acc + style.outerHTML, '');
    },
    generateHtml() {
      const printContent = this.$refs.printableCard.$el.outerHTML;
      const styles = this.getStyles();
      const html = `
          <html>
            <head>
              ${styles}
            </head>
            <body>
              ${printContent}
            </body>
          </html>
        `;
      return html;
    },
   printPDF() {
      const html = this.generateHtml();
      // Create an hidden iframe
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      // Add the iframe to the DOM
      document.body.appendChild(iframe);
      // write the html to the iframe
      iframe.contentDocument.write(html);
      iframe.contentDocument.close();
      // The iframe has to be loaded completely before you can print it
      iframe.onload = function() {
        // print the iframe
        iframe.contentWindow.print();
        // remove the iframe from the DOM
        document.body.removeChild(iframe);
      };
      this.showPopup = false;
    },
    submitMessage(){
      if(!this.userEmail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)){
        this.$swal({
          icon: 'error',
          title: 'Invalid E-mail',
          html: 'Please enter a valid e-mail, example: "example@example.com"',
          showConfirmButton: true,
          timer: 1500
        });
        return;
      }

      this.$swal
        .fire({
          title: "Send Quote Copy",
          text: "Are you sure you want to send an email with a copy of this quote?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, send it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // open swal with loading
            const WaitingSwal = this.$swal.fire({
              title: "Loading...",
              allowOutsideClick: false,
              // hide all buttons
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: false,
              onBeforeOpen: async () => {
                this.$swal.showLoading();
                // send email
                const status = this.quote.status?.value || "N/A"
                await axios.post("api/admin/mail/quote/send", {
                  email: this.userEmail,
                  mainContact: this.quote.main_contact,
                  mainName: this.quote.main_name,
                  secoundaryName: this.quote.name,
                  secoundaryContact: this.quote.phone,
                  status: status,
                  subsQtd: this.quote.subscriptions_requested
                }).then((msg) => {
                    this.$swal.fire(
                    "Email Sent!",
                    "The quote details has been sent to the user email address.",
                    "success"
                  );
                })
                .catch((error) => {
                  this.$swal.fire({
                    title: 'Oops! Something Went Wrong!',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return;
                });
                
              },
            });
          }
        })
       

    },
    sendEmailModal(){
      this.showEmailPopup = true;
    },
    goToOrganizationPage() {
      if(this.quote.organization){
        this.$router.push({
          name: "edit",
          params: { uid: this.quote.organization },
        });
      }
    },
    viewAttachment(row) {
      window.open(row.url, "_blank");
    },
    pricePerQuote(value, isPDF = false) {
      const SPCalculator = new SeatPriceCalculator(value);
      let discount = SPCalculator.calculateDiscountPercentage(value);

      discount = discount !== 0 ? '('+ discount + '% off)' : '';
      const message1 = SeatPriceCalculator.formatCurrency(SPCalculator.price_per_seat) + ' - Price per Subscription ' + discount;
      const message2 = SeatPriceCalculator.formatCurrency(SPCalculator.price_per_seat) + ' ' + discount;
      const output = isPDF ? message2 : message1;
      return output;
    },
    calcTotalPrice(value) {
      const SPCalculator = new SeatPriceCalculator(value);
      return SeatPriceCalculator.formatCurrency(SPCalculator.price_per_seat * value) + ' - (' + value + ' Individual Subscriptions)';
    },
    async updateQuote() {
      //update quote with id this.$route.params.id
      try {
        this.$swal.fire({
          title: 'Saving...',
          showConfirmButton: false,
          allowOutsideClick: false,
          onBeforeOpen: () => {
            this.$swal.showLoading()
          },
        });
        const status_index = this.quote.status?.index;
        await firebase
          .firestore()
          .collection("Quotes")
          .doc(this.$route.params.id)
          .update({
            status: status_index,
            main_contact: this.quote.main_contact,
            main_name: this.quote.main_name,
            name: this.quote.name,
            phone: this.quote.phone,
            state: this.quote.state,
            subscriptions_requested: this.quote.subscriptions_requested,
          });

        this.$swal.close();
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo deu errado!',
        });
      }
    },
  },
};
</script>
<style  scoped>
@media print {
    .sampleOne[hidden] {
        display: block !important;
    }
}
</style>
